import clsx from "clsx";
import { Grid } from "@hiltermann/components/ui";
import { GetDynamicContentItemProps } from "./DynamicContentBlock/types";
import FaqAccordion from "./FaqBlock/FaqAccordion";

export type FaqSingleSubjectBlockProps = GetDynamicContentItemProps<"FaqSingleSubjectBlock">;

export const FaqSingleSubjectBlock: React.FC<FaqSingleSubjectBlockProps> = (props) => {
  if (!props.subject?.[0].faq_items) return null;
  return (
    <section data-block="FaqSingleSubjectBlock" className={clsx(props.className, "")}>
      <Grid className="relative z-10 min-h-40 bg-white">
        <div className="tablet:px-14 col-span-full py-10">
          <p className="text-p text-secondary-burgundy tablet:mb-10 tablet:text-h3-sm mb-2 line-clamp-1 font-serif tracking-wider">
            Meer regelen
          </p>
          <h1 className="text-h1-sm tablet:mb-12 tablet:text-h1-md line-clamp-2 font-sans font-bold tracking-wider">
            veelgestelde vragen
          </h1>
          <FaqAccordion items={props.subject[0].faq_items} className="tablet:mt-11 mt-8" />
        </div>
      </Grid>
    </section>
  );
};
