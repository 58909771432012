import React from "react";
import "swiper/css";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

interface CarouselProps<T> {
  data: T[];
  renderItem: (item: T) => React.ReactNode;
}

const Carousel = <T,>({ data, renderItem }: CarouselProps<T>) => {
  return (
    <Swiper
      modules={[Navigation, Pagination]}
      slidesPerView="auto"
      spaceBetween={16}
      pagination={{
        el: ".swiper-pagination",
        clickable: false,
        type: "bullets",
        bulletClass: "w-2.5 h-2.5 bg-grey-asphalt rounded-full opacity-100 cursor-pointer",
        bulletActiveClass: "!w-4 !h-4 !bg-secondary-burgundy",
      }}
      centeredSlides={true}
      className="!overflow-visible"
    >
      {data.map((item, index) => {
        return (
          <SwiperSlide className="flex !h-auto !w-[82%] !shrink-0" key={index}>
            {renderItem(item)}
          </SwiperSlide>
        );
      })}
      <div className="swiper-pagination !bottom-0 mt-6 !flex !flex-1 !items-center !justify-center !gap-3" />
    </Swiper>
  );
};

export default Carousel;
