import { Grid } from "@hiltermann/components/ui";
import clsx from "clsx";

interface ScriptBlockProps extends React.HTMLAttributes<HTMLDivElement> {
  input: string;
}

export const ScriptBlock: React.FC<ScriptBlockProps> = ({ input, ...props }) => {
  return (
    <Grid>
      <div
        id="script-block"
        {...props}
        data-testid="script-block"
        className={clsx("col-span-full", props?.className)}
        dangerouslySetInnerHTML={{ __html: input }}
      />
    </Grid>
  );
};
